//COOKIE
export const DEFAULT_ULTRACARD_COOKIE_DAYS = 1;

export const COOKIE_DOMAIN = '.greenvalleynaturals.com';

export const PRODUCTION_DOMAIN_URL = 'https://www.greenvalleynaturals.com';

export const COUPON_COOKIE_NAME = 'coupon';

export const DEFAULT_OPTOUT_COOKIE_DAYS = 1000;

///TEXT

export const NO_ARTICLES = 'There is no articles in this category';

export const PRODUCT_ALLERGEN_KEY = 'exists';

export const ONE_TIME = 'onetime';

export const AUTO_SHIP = 'subscription';

export const METADATA_SITE_NAME = 'Green Valley Naturals';

export const METADATA_COUNTRY = 'USA';

export const METADATA_CATEGORY = 'Nutrition';

export const DEFAULT_AUTOPLAY_TIMEOUT = 10_000;

export const COMPANY_NAME = 'Welcome to Green Valley Naturals';

export const NOT_PRODUCT_SKU_AVAILABLE = 'this product dont have sku';

export const WEBSITE_NAME = 'Green Valley Naturals';

export const CUSTOMER_SUPPORT_EMAIL = 'customerservice@greenvalleynaturalsolutions.com';

export const BASE_CATEGORY_PORTAL_PATH = '/learn/category/';

export const BASE_CATEGORY_PORTAL_PATH_SITEMAP = '/learn/category'; //TODO:
